exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-category-natal-js": () => import("./../../../src/pages/category/natal.js" /* webpackChunkName: "component---src-pages-category-natal-js" */),
  "component---src-pages-category-orang-kudus-js": () => import("./../../../src/pages/category/orang-kudus.js" /* webpackChunkName: "component---src-pages-category-orang-kudus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-page-archive-js": () => import("./../../../src/templates/article-page-archive.js" /* webpackChunkName: "component---src-templates-article-page-archive-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */)
}

